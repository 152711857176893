import React from 'react';

const MapIframe = props => (
    <iframe
        id="gmap_canvas"
        title="Map"
        width="100%"
        height="70%"
        src={`https://maps.google.com/maps?q=${props.data.addressLine1}, ${props.data.city}, ${props.data.state} ${props.data.zipCode}&t=&z=17&ie=UTF8&iwloc=&output=embed`}
        frameBorder="0"
        scrolling="no" 
    >
    </iframe>
)
export default MapIframe;

