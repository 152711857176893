import React, { Component } from "react";
import { Button, Grid, Dropdown, Image, Modal } from "semantic-ui-react";

class CatalogViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      activeStore: "Berks",
      stores:[ "Berks", "Camden", "Luzerne"]
    };
  }

  nextPage = () =>
    this.setState({
      currPage: this.state.currPage + 1
    })

  prevPage = () =>
    this.setState({
      currPage: this.state.currPage - 1
    })

  selectStore = (e, { text }) =>{
    this.setState({
      activeStore: text
    })}

  render(){
    const isPrevButtonDisabled = this.state.currPage === 1;
    const isNextButtonDisabled = this.state.currPage === 4;
    return(
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            Store Selected: <Dropdown selection text={this.state.activeStore === undefined ? "Select Store" : this.state.activeStore}>
              <Dropdown.Menu>
                  {
                      this.state.stores.map(store => (
                          <Dropdown.Item key={store} text={store} onClick={this.selectStore} />
                      ))
                  }
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle" columns={3}>
          <Grid.Column textAlign="right" mobile={3} tablet={3} computer={3}>
            <Button className="MainGreenColor" inverted circular disabled={isPrevButtonDisabled} icon="chevron left" onClick={() => this.prevPage()} />
          </Grid.Column>
          <Grid.Column mobile={10} tablet={10} computer={10}>
            <Modal basic closeIcon trigger={
              <Image centered fluid src={`https://www.cousinssupermarket.com/circular/${this.state.activeStore.toLowerCase()}/0${this.state.currPage}.jpg`} />
            }>
              <Modal.Content>
                <Image centered size="huge" src={`https://www.cousinssupermarket.com/circular/${this.state.activeStore.toLowerCase()}/0${this.state.currPage}.jpg`} />
              </Modal.Content>
            </Modal>
          </Grid.Column>
          <Grid.Column textAlign="left" mobile={3} tablet={3} computer={3}>
            <Button className="MainGreenColor" inverted circular disabled={isNextButtonDisabled} icon="chevron right"  onClick={() => this.nextPage()} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

    );
  }
}
export default CatalogViewer;