import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading = props => (
    <Dimmer inverted active={props.isLoading}>
        <Loader content="Loading" />
    </Dimmer>
)
export default Loading;

