import React from 'react';
import { Image, Header, Radio, Form } from 'semantic-ui-react';
import "../Styles/CakeOrderStyles.css";

const CakeFormField = props => (
<Form.Field>
    <Header textAlign="center" icon>
        {
            props.field.imageUrl !== undefined ?
                <Image className="CakeImage" size="tiny" centered src={props.field.imageUrl} />
            :
                null
        }
        <Header className="CakeSizeHeader" size="tiny" content={props.field.name} subheader={props.field.price !== undefined ? `$${props.field.price.toFixed(2)}` : null} />
        <Radio className="CustomRadioButton" name={props.radioName} cost={props.costName} value={props.field.key} price={props.field.price !== undefined ? props.field.price.toFixed(2) : 0} checked={props.radioState === props.field.key} onChange={props.handleChangeFunc}/>
    </Header>
</Form.Field>

)
export default CakeFormField;

