import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { Grid, Divider } from 'semantic-ui-react';
import SiteHeader from "./Stateful Components/SiteHeader";
import Routes from "./Routes/BaseRoutes";
import Footer from "./Stateful Components/SiteFooter";
import "./Main.css";

const App = () => (
  <BrowserRouter>
    <ScrollToTop>
      <div>
        <SiteHeader />
        <Grid className="MainDiv">
          <Grid.Row>
            <Grid.Column>
                <Routes />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Footer />
      </div>
    </ScrollToTop>
  </BrowserRouter>
);
export default App;
