import React, { Component } from "react";
import { Form, Label, Segment, Statistic, Modal, Grid, Header, Divider } from "semantic-ui-react";
import axios from "axios";
import CakeFormField from "../Stateless Components/CakeFormField";
import FrostingDesign from "../Media/FrostingDesign.png";
import BorderDesign from "../Media/BorderDesign.png";
import FillingDesign from "../Media/FillingDesign.png";
import DesignDesign from "../Media/DesignDesign.png";
import Loading from "../Stateless Components/Loading";


class CakeOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      totalPrice: 0,
      cakeCost: 0,
      frostingCost: 0,
      fillingCost: 0,
      borderCost: 0,
      designCost: 0,
      imageName: undefined,
      imageUrl: undefined
    };
  }

  async getCakePrices(){
    return axios({
      method: 'get',
      baseURL: 'https://cousinssupermarket.com/',
      url: '/api/CakePricesApi.php'
    })
  }

  async getCakeDesigns(){
    return axios({
      method: 'get',
      baseURL: 'https://cousinssupermarket.com/',
      url: '/api/CakeDesignsApi.php'
    })
  }

  componentDidMount() {
    axios.all([
      this.getCakePrices(),
      this.getCakeDesigns()
    ]).then(axios.spread((cakePricesRes, cakeDesignsRes) => {
      this.setState({
        cakePricesData: cakePricesRes.data,
        cakeDesignsData: cakeDesignsRes.data,
        isLoading: false
      })
    })).catch(error => {
      alert("Hmm... Something went wrong! Please try again, or contact us at feedback@cousinssupermarket.com if issue persists!")
    })
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) { 
    let cakeCost = this.state.cakeCost; 
    let frostingCost = this.state.frostingCost;
    let fillingCost = this.state.fillingCost;
    let borderCost = this.state.borderCost;
    let designCost = this.state.designCost;

    if (cakeCost !== prevState.cakeCost || frostingCost !== prevState.frostingCost || fillingCost !== prevState.fillingCost || borderCost !== prevState.borderCost || designCost !== prevState.designCost) { 
      this.setState({
        totalPrice: cakeCost + frostingCost + fillingCost + borderCost + designCost
      })
    }
  }

  handleChange = (e, { name, cost, value, price }) => this.setState({ [name]: value, [cost]: parseFloat(price) })

  submitCake = () => alert("Cake Ordered: " + JSON.stringify(this.state))

  render(){
    const currCakeAddOns = this.state.cakePricesData !== undefined ? this.state.cakePricesData.addOns[this.state.selectedCake] : null;
    return(
      this.state.isLoading ?
        <Loading isLoading={this.state.isLoading} />
      :
        <Segment placeholder>
          <Form onSubmit={this.submitCake}>
            <Label color='green' ribbon content="Start by selecting your cake size!" />
            <Form.Group unstackable widths={this.state.cakePricesData.cakes.length}>
              {
                this.state.cakePricesData.cakes.map(cake =>
                  <CakeFormField key={cake.name} field={cake} radioName="selectedCake" costName="cakeCost" radioState={this.state.selectedCake} handleChangeFunc={this.handleChange} />
                )
              }
            </Form.Group>
            {
              currCakeAddOns === undefined ?
                null
              :
                <div>
                  <Label color='green' ribbon content="Select our design! Choose from catalog or upload your own!" />
                  <Segment basic>
                    <Grid columns={2}>
                      <Grid.Column>
                          <Form.Input
                            icon='pencil'
                            iconPosition='left'
                            label='Describe Image'
                            placeholder='i.e. Spiderman'
                            name="selectedImageDescription"
                            onChange={this.handleChange}
                          />
                          <Form.Input
                            icon='linkify'
                            iconPosition='left'
                            label='Paste Image Url'
                            placeholder='https://example.com/image.png'
                            name="selectedImageUrl"
                            onChange={this.handleChange}
                          />
                      </Grid.Column>
                      <Grid.Column verticalAlign='middle'>
                        <Modal centered={true} closeIcon trigger={<Segment textAlign="center" raised content={<Header size="medium" content="Choose from our design catalog" />} />}>
                          <Modal.Content scrolling>
                            <Form.Group unstackable>
                              {
                                this.state.cakeDesignsData.designs.map(design =>
                                  <CakeFormField key={design.name} field={design} imageUrl={design.imageUrl} radioName="selectedCakeDesign" costName="cakeDesignCost" radioState={this.state.selectedCakeDesign} handleChangeFunc={this.handleChange} />
                                )
                              }
                            </Form.Group>
                          </Modal.Content>
                        </Modal>
                      </Grid.Column>
                    </Grid>
                    <Divider vertical content="OR" />
                  </Segment>
                  <Label color='green' ribbon content="Customize your cake to your liking!" />
                  <Divider hidden />
                  <Grid columns={4} doubling>
                    <Grid.Column>
                      <Modal centered={true} closeIcon trigger={<Segment textAlign="center" raised content={<Header size="medium" icon content="Frosting" image={FrostingDesign} />} />}>
                        <Modal.Content scrolling>
                          <Form.Group unstackable>
                            {
                              currCakeAddOns.frostings.map(frosting =>
                                <CakeFormField key={frosting.name} field={frosting} radioName="selectedFrosting" costName="frostingCost" radioState={this.state.selectedFrosting} handleChangeFunc={this.handleChange} />
                              )
                            }
                          </Form.Group>
                        </Modal.Content>
                      </Modal>
                    </Grid.Column>
                    <Grid.Column>
                      <Modal centered={true} closeIcon trigger={<Segment textAlign="center" raised content={<Header size="medium" icon content="Filling" image={FillingDesign} />} />}>
                        <Modal.Content scrolling>
                          <Form.Group unstackable>
                            {
                              currCakeAddOns.fillings.map(filling =>
                                <CakeFormField key={filling.name} field={filling} radioName="selectedFilling" costName="fillingCost" radioState={this.state.selectedFilling} handleChangeFunc={this.handleChange} />
                              )
                            }
                          </Form.Group>
                        </Modal.Content>
                      </Modal>
                  </Grid.Column>
                  <Grid.Column>
                      <Modal centered={true} closeIcon trigger={<Segment textAlign="center" raised content={<Header size="medium" icon content="Border" image={BorderDesign} />} />}>
                        <Modal.Content scrolling>
                          <Form.Group unstackable>
                            {
                              currCakeAddOns.borders.map(border =>
                                <CakeFormField key={border.name} field={border} radioName="selectedBorder" costName="borderCost" radioState={this.state.selectedBorder} handleChangeFunc={this.handleChange} />
                              )
                            }
                          </Form.Group>
                        </Modal.Content>
                      </Modal>
                  </Grid.Column>
                  <Grid.Column>
                      <Modal centered={true} closeIcon trigger={<Segment textAlign="center" raised content={<Header size="medium" icon content="Design" image={DesignDesign} />} />}>
                        <Modal.Content scrolling>
                          <Form.Group unstackable>
                            {
                              currCakeAddOns.designs.map(design =>
                                <CakeFormField key={design.name} field={design} radioName="selectedDesign" costName="designCost" radioState={this.state.selectedDesign} handleChangeFunc={this.handleChange} />
                              )
                            }
                          </Form.Group>
                        </Modal.Content>
                      </Modal>
                  </Grid.Column>
                  </Grid>
                  <Statistic>
                    <Statistic.Label>Your Total</Statistic.Label>
                    <Statistic.Value>${this.state.totalPrice.toFixed(2)}</Statistic.Value>
                  </Statistic>
                  <Form.Button positive disabled={this.state.selectedCake === undefined} content="Submit Order" />
                </div>
            }
          </Form>
        </Segment>
    );
  }
}
export default CakeOrder;