import React from 'react';
import { Header } from 'semantic-ui-react';
import "../Styles/PageStyles.css";

const PageHeader = props => (
    <Header as='h2' textAlign="center">
        <Header.Content className="PageHeader">{props.mainTitle}</Header.Content>
    </Header>
)
export default PageHeader;

