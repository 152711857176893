import { Grid } from "semantic-ui-react";
import CakeOrder from "../Stateful Components/CakeOrder";
import PageHeader from "../Stateless Components/PageHeader";
import PageSubHeader from "../Stateless Components/PageSubHeader";

const Ordering = () => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <PageHeader mainTitle="Online Pre-Ordering"/>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <PageSubHeader icon="birthday cake" mainTitle="Cake Creation and Pre-order" />
                <CakeOrder />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
export default Ordering;