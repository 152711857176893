import React, { Component } from "react";
import axios from 'axios';
import { Grid } from "semantic-ui-react";
import CarouselSlider from "../Stateless Components/CarouselSlider";
import Loading from "../Stateless Components/Loading";

class WeeklySpecialsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weeklySpecials:[],
      isLoading: true
    };
  }

  componentDidMount() {

    axios({
      method: 'get',
      baseURL: 'https://cousinssupermarket.com/',
      url: '/api/WeeklySpecialsApi.php'
    }).then(res =>
      this.setState({
        weeklySpecials: res.data,
        isLoading: false
      })
    )
    .catch(error => {
      let weeklySpecials = undefined;

      this.setState({
        bannerMessages: weeklySpecials,
        isLoading: false
      });
    });
  }



  render(){
    return(
      <Grid centered>
        <Grid.Row>
          <Grid.Column computer={12} mobile={16} tablet={14}>
            {
              this.state.isLoading ?
                <Loading isLoading={this.state.isLoading} />
              :
                this.state.weeklySpecials !== undefined ?
                  <CarouselSlider items={this.state.weeklySpecials} />
                :
                  null
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default WeeklySpecialsCarousel;