import { Grid, Segment } from "semantic-ui-react";
import PageHeader from "../Stateless Components/PageHeader";
import PageSubHeader from "../Stateless Components/PageSubHeader";
import EmploymentApplicationForm from "../Stateful Components/EmploymentApplicationForm";

const Careers = () => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <PageHeader mainTitle="Careers"/>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <PageSubHeader icon="info circle" mainTitle="Career Policies"/>
                <Segment basic content={"Cousins Supermarket is an \"Equal Opportunity Employer\". We do not discriminate on the basis of race, color, religion, national origin, sex, age, disability, or any other status protected by law or regulation. It is our intention that all qualified applicants be given equal opportunity and that selection decisions be based on job-related factors."} />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <PageSubHeader icon="clipboard" mainTitle="Employment Application"/>
                <Segment tertiary raised>
                    <EmploymentApplicationForm />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
export default Careers;