import React, { Component } from "react";
import axios from "axios";
import { Grid, Form, Segment, Header } from "semantic-ui-react";

class SubmitReview extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value});

  submitReview = () => {
    this.setState({
      isLoading: true,
      isDisabled: true
    });

    let payload = this.state;

    axios({
      method: 'post',
      baseURL: 'https://cousinssupermarket.com/',
      url: '/api/ReviewSubmissionApi.php',
      data: payload
    }).then(res => {
      this.setState({
        isLoading: false
      });

      alert("Successfully submitted review! Thank you!")
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        isDisabled: false
      });

      alert("Hmm... Something went wrong! Please try again, or contact us at feedback@cousinssupermarket.com if issue persists!")
    });
  }

  render(){
    return(
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={this.submitReview}>
              <Segment secondary>
                <Form.Group unstackable>
                  <Form.Input width="8" fluid name="visitDate" label='When did you visit?' placeholder={new Date().toLocaleDateString()} onChange={this.handleChange} />
                  <Form.Input width="8" fluid name="name" label='Name' placeholder='Name' onChange={this.handleChange} />
                </Form.Group>
                <Form.Group unstackable>
                  <Form.Input width="8" fluid name="emailAddress" label='Email Address' placeholder='Email Address' onChange={this.handleChange} />
                  <Form.Input width="8" fluid name="telephoneNumber" label='Telephone Number' placeholder='Telephone Number' onChange={this.handleChange} />
                </Form.Group>
                <Form.TextArea name='shoppingExperience' label="Tell us about your shopping experience" placeholder='Enter your feedback here...' onChange={this.handleChange} />
                <Header as="h6" icon='info' content='Please make sure all infomation above is correctly filled out. We may contact you once we review your feedback to address your concerns and/or ask for additional information in order for us to cater to your feedback.' />
                <Form.Button loading={this.state.isLoading} disabled={this.state.isDisabled} primary content="Submit Feedback" />
              </Segment>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default SubmitReview;