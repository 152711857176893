import React from "react";
import { Grid, Item } from "semantic-ui-react";

const StoreDepartments = props => (
  <Grid centered columns={2}>
    {
      props.Departments.map((department, index) =>
      <Grid.Column key={index} mobile={16} tablet={16} computer={8}>
        <Item.Group unstackable>
          <Item>
            <Item.Image size='tiny' src={department.imageUrl} />
            <Item.Content verticalAlign='middle'>
              <Item.Header className="StoreDepartmentMainHeader">{department.title}</Item.Header>
              <Item.Meta className="StoreDepartmentDescription">{department.description}</Item.Meta>
            </Item.Content>
          </Item>
        </Item.Group>
      </Grid.Column>
      )
    }
  </Grid>
)
export default StoreDepartments;