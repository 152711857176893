import React, { Component } from "react";
import axios from "axios";
import { Grid, Form, Segment, Header } from "semantic-ui-react";

const empTypes = [
  { key: 'fullTime', text: 'Full-time', value: 'fullTime' },
  { key: 'partTime', text: 'Part-time', value: 'partTime' },
  { key: 'tempTime', text: 'Temporary', value: 'tempTime' }
]

const locations = [
  { key: 'berks', text: 'Berks', value: 'Berks' },
  { key: 'camden', text: 'Camden', value: 'Camden' },
  { key: 'luzerne', text: 'Luzerne', value: 'Luzerne' }
]

class EmploymentApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value});

  submitApplication = () => {
    this.setState({
      isLoading: true,
      isDisabled: true
    });

    let payload = this.state;

    axios({
      method: 'post',
      baseURL: 'https://cousinssupermarket.com/',
      url: '/api/EmploymentApplicationSubmissionApi.php',
      data: payload
    }).then(res => {
      this.setState({
        isLoading: false
      });

      alert("Successfully submitted application! Thank you!")
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        isDisabled: false
      });

      alert("Hmm... Something went wrong! Please try again, or contact us at feedback@cousinssupermarket.com if issue persists!")
    });
  }

  render(){
    return(
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={this.submitApplication}>
              <Segment secondary>
              <Form.Group unstackable>
                <Form.Input width="6" fluid name="todaysDate" label='Todays Date' placeholder={new Date().toLocaleDateString()} onChange={this.handleChange} />
                <Form.Input width="10" fluid name="jobPosition" label='Job Applied For' placeholder='Position, i.e. Cashier' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group unstackable>
                <Form.Input width="6" fluid name="startDate" label='Start Date' placeholder='Desired start date' onChange={this.handleChange} />
                <Form.Select width="10" fluid name="empType" label='Employment Type' options={empTypes} placeholder='Employment Type' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Input width="5" fluid name="lastName" label='Last Name' placeholder='Last name' onChange={this.handleChange} />
                <Form.Input width="5" fluid name="firstName" label='First Name' placeholder='First name' onChange={this.handleChange} />
                <Form.Input width="3" fluid name="middleName" label='Middle Name' placeholder='Middle name' onChange={this.handleChange} />
                <Form.Input width="3" fluid name="telephoneNumber" label='Telephone Number' placeholder='Telephone Number' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Input width="5" fluid name="streetAddress" label='Street Address' placeholder='Street Address' onChange={this.handleChange} />
                <Form.Input width="5" fluid name="city" label='City' placeholder='City' onChange={this.handleChange} />
                <Form.Input width="3" fluid name="state" label='State' placeholder='State' onChange={this.handleChange} />
                <Form.Input width="3" fluid name="zipCode" label='Zip Code' placeholder='Zip Code' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Input width="6" fluid name="emailAddress" label='Email Address' placeholder='Email Address' onChange={this.handleChange} />
                <Form.Select width="10" fluid name="desiredLocation" label='Desired Work Location' options={locations} placeholder='Desired Location' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Checkbox name="age" label='I am 18 years of age or older' value="true" onClick={this.handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Checkbox name="workPermit" label='I am legally eligible to work in the U.S. and can provide proof' value="true" onClick={this.handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Checkbox name="previousEmployee" label='I have previously worked at Cousins Supermarket sometime in the last 10 years' value="true" onClick={this.handleChange} />
              </Form.Group>
              </Segment>
              <Segment secondary>
                <Form.TextArea name='previousWorkExperience' label="Tell us about your Previous Work Experience" placeholder='Previous Work Experience' onChange={this.handleChange} />
              </Segment>
              <Segment secondary>
                <Form.TextArea name='about' label="Tell us about yourself" placeholder='Why you would be a great fit at Cousins Supermarket' onChange={this.handleChange} />
              </Segment>
              <Header as="h6" icon='info' content='Please make sure all infomation above is correctly filled out. We will contact you back if there are any openings for the position you are looking for. We may ask for additional infomation, such as previous employer and/or level of education, any previous law violations and/or convictions, and other personal information.' />
              <Form.Button loading={this.state.isLoading} disabled={this.state.isDisabled} primary content="Submit Application" />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default EmploymentApplicationForm;