import { Grid } from "semantic-ui-react";
// import CatalogCircular from "../Stateful Components/CatalogCircular";
import CatalogViewer from "../Stateful Components/CatalogViewer";
import PageHeader from "../Stateless Components/PageHeader";
import PageSubHeader from "../Stateless Components/PageSubHeader";

const CurrentDeals = () => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <PageHeader mainTitle="Current Deals"/>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <PageSubHeader icon="shop" mainTitle="Bi-Weekly Ad" />
                {/* <CatalogCircular /> */}
                <CatalogViewer />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
export default CurrentDeals;