import React from 'react';
import { Header, Grid, Label, Icon } from "semantic-ui-react";
import FooterData from "../Data/FooterData.json";
import "../Styles/FooterStyle.css";

const SiteFooter = () => (
  <Grid stackable columns={1} className="FooterDiv MainGreenColor">
    <Grid.Column textAlign="center">
      <Header color="black" as='h1' content={FooterData.mainTitle} subheader={FooterData.subTitle} />
    </Grid.Column>
    <Grid.Column textAlign="center">
      <Header textAlign="center" color="black" as='h4' content="Follow us on:" />
      <Label.Group size='large'>
        {
          FooterData.externalSites.map((site, index) =>
            <Label key={index} as='a' onClick={() => window.location.href = site.link}>
              <Icon color={site.color} name={site.icon}/>
              {site.name}
            </Label>
          )
        }
      </Label.Group>
    </Grid.Column>
  </Grid>
)

export default SiteFooter;