import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Image } from "semantic-ui-react";
import HeaderData from "../Data/HeaderData.json";
import '../Styles/SiteHeader.css';

const SiteHeader = () => (
  <Menu borderless>
    <Menu.Menu position='left'>
      <Menu.Item>
        <Image className="SiteHeaderMainLogo" as={Link} to="/" size='small' src="https://www.cousinssupermarket.com/media/CousinsMainLogo.png"/>
      </Menu.Item>
    </Menu.Menu>
    <Menu.Menu position='right'>
      <Dropdown icon='bars' item text='Menu'>
        <Dropdown.Menu>
          {
            HeaderData.menuItems.map(item =>
              <Dropdown.Item key={item.name} as={Link} to={item.link} icon={item.icon} text={item.name} description={item.description} />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Menu>
  </Menu>
)
export default SiteHeader;