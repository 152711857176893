import React from 'react';
import { Segment, Header, Image, Grid } from 'semantic-ui-react';
import "../Styles/BannerMessageStyles.css";

const colors = ['blue', 'red', 'green','violet','teal','orange','yellow','olive','purple','pink','brown','grey','black']

const MessageBoard = props => (
    <Grid columns={1}>
        {
            props.messages.map((message, index) =>
                <Grid.Column key={index}>
                    <Segment raised inverted color={colors[index]} className="MessageBoardSegment">
                        {
                            message.mainTitle !== undefined ?
                                <Header>
                                    {
                                        message.imageUrl !== undefined ?
                                        <Image size="tiny" src={message.imageUrl} />
                                        : null
                                    }
                                    <Header.Content>
                                        {message.mainTitle}
                                        <Header.Subheader>{message.subTitle}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            :
                                <Image fluid src={message.imageUrl} />
                        }
                    </Segment>
                </Grid.Column>
            ) 
        }

    </Grid>
)
export default MessageBoard;

