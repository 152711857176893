import React from 'react';
import { Divider, Header, Icon } from 'semantic-ui-react';
import "../Styles/PageStyles.css";

const PageSubHeader = props => (
    <Divider horizontal>
        <Header as='h4' textAlign="center" className="PageSubHeader">
            <Icon name={props.icon} />
            <Header.Content>{props.mainTitle}</Header.Content>
        </Header>
    </Divider>
)
export default PageSubHeader;

