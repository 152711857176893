import { Grid, Segment, Image } from "semantic-ui-react";
import PageHeader from "../Stateless Components/PageHeader";
import PageSubHeader from "../Stateless Components/PageSubHeader";
import SubmitReview from "../Stateful Components/SubmitReview";
import "../Styles/AboutStyles.css";

const About = () => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <PageHeader mainTitle="About Us"/>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <PageSubHeader icon="info circle" mainTitle="Who are we?" />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Image size="big" src="https://www.cousinssupermarket.com/media/BerksMainFront.jpg" />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Segment className="AboutMainParagraph" basic content="Cousin's Supermarket opened its first store in Philadelphia, PA 1976. Our goal was to provide exceptional service and quality Meats, Produce and Groceries at an affordable price to our customers. We are one of the oldest locally owned and operated stores that offers a range of grocery and food products in the greater Philadelphia region." />   
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <PageSubHeader icon="info circle" mainTitle="What are our values?" />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Image size="big" src="https://www.cousinssupermarket.com/media/LuzerneMainFront.jpg" />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Segment className="AboutMainParagraph" basic content="We maintain our grocery, deli, produce, seafood, meat and bakery departments to the highest standards. We offer a selection of products, such as coffee, tropical fruits and vegetables, meat, cheese, roast beef, steaks, boneless chicken and a variery of Deli meats. We feature cakes, Italian bread, pies and cookies for various events and special occasions." />   
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <PageSubHeader icon="info circle" mainTitle="Our committment to you, our customer" />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Image size="big" src="https://www.cousinssupermarket.com/media/CamdenMainFront.jpg" />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Segment className="AboutMainParagraph" basic content="We are committed to providing our customers with the best shopping experience at all our locations. We continously are looking for areas to improve and are urging our valued customers to contact us with any comments and/or feedback!" />  
                <SubmitReview /> 
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
export default About;