import { Grid, Button, Image } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import BannerMessages from "../Stateful Components/BannerMessages";
import WeeklySpecialsCarousel from "../Stateful Components/WeeklySpecialsCarousel";
import StoreInfo from "../Stateful Components/StoreInfo";
import PageSubHeader from "../Stateless Components/PageSubHeader";

const HomePage = () => (
    <Grid centered>
        <Grid.Row verticalAlign="middle">
            <Grid.Column textAlign="center" computer={10} mobile={16} tablet={16}>
                <Image centered size="large" src="https://www.cousinssupermarket.com/media/HomePageMainBanner.png" />
                <PageSubHeader icon="dollar sign" mainTitle="Current Bi-Weekly Specials" />
                <WeeklySpecialsCarousel />
                <Button inverted className="MainGreenColor" content="View Full Circular" as={Link} to="/deals" />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={16}>
                <PageSubHeader icon="announcement" mainTitle="Special Messages From Us..." />
                <BannerMessages />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <StoreInfo />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
export default HomePage;