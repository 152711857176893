import { Route, Switch } from "react-router-dom";
import About from "../Page Templates/About";
import Careers from "../Page Templates/Careers";
import CurrentDeals from "../Page Templates/CurrentDeals";
import Home from "../Page Templates/HomePage";
import Ordering from "../Page Templates/Ordering";

const BaseRoutes = () => (
  <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/deals" component={CurrentDeals}/>
    <Route exact path="/ordering" component={Ordering}/>
    <Route exact path="/about" component={About}/>
    <Route exact path="/careers" component={Careers}/>
    <Route component={Home}/>
  </Switch>
)
export default BaseRoutes;