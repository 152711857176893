import React from "react";
import { Card, Divider, Grid, Header, List, Label } from "semantic-ui-react";
import MapIframe from "./MapIframe";

const StoreLocations = props => (
  <Grid centered columns={3}>
    {
      props.Locations.map(location =>
        <Grid.Column textAlign="center" key={location.address.zipCode} mobile={15} computer={5} tablet={15}>
          <Card centered color='red' className="StoreLocationsCard">
            <MapIframe data={location.address} />
            <Card.Content>
              <Card.Header>{location.subTitle}</Card.Header>
              <Card.Meta>{location.address.addressLine1}</Card.Meta>
              <Card.Meta>{`${location.address.city}, ${location.address.state} ${location.address.zipCode}`}</Card.Meta>
              {
                location.specialFeatures !== undefined ?
                  location.specialFeatures.map((feature, index) => <Label key={index} size="mini" color='red' tag content={feature} />)
                :
                  null
              }
              <Card.Description>
                <Divider fitted hidden />
                <List>
                  {
                    location.hoursOfOperation.map((hoo, index) =>
                      <List.Item key={`${hoo.day}-${index}`}>
                        <List.Header>{hoo.day}</List.Header>
                        {`${hoo.timeOpen} - ${hoo.timeClose}`}
                      </List.Item>
                    )
                  }
                </List>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Header textAlign="center" as="a" icon='phone' size="tiny" content={location.phoneNumber} onClick={() => window.location.href = `tel: ${location.phoneNumber}`} />
            </Card.Content>
          </Card>
        </Grid.Column>
      )
    }
  </Grid>
)
export default StoreLocations;