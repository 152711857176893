import React, { Component } from "react";
import axios from "axios";
import { Grid } from "semantic-ui-react";
import MessageBoard from "../Stateless Components/MessageBoard";
import Loading from "../Stateless Components/Loading";

class BannerMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerMessages:[],
      isLoading: true
    };
  }

  componentDidMount() {
    axios({
      method: 'get',
      baseURL: 'https://cousinssupermarket.com/',
      url: '/api/BannerMessagesApi.php'
    }).then(res =>
      this.setState({
        bannerMessages: res.data,
        isLoading: false
      })
    )
    .catch(error => {
      let defaultMessages = [];
      
      defaultMessages.push({
        mainTitle: "Thank You For Shopping With Cousins!"
      })

      this.setState({
        bannerMessages: defaultMessages,
        isLoading: false
      });
    });
  }

  render(){
    return(
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            {
              this.state.isLoading ?
                <Loading isLoading={this.state.isLoading} />
              :
                <MessageBoard messages={this.state.bannerMessages} />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default BannerMessages;