import React from 'react';
import Carousel, { slidesToShowPlugin, arrowsPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Icon } from 'semantic-ui-react';
import CarouselCard from './CarouselCard';


const CarouselSlider = props => (
    <Carousel animationSpeed={500} plugins={
        [
            'centered',
            'infinite',
            {
                resolve: slidesToShowPlugin,
                options: {
                    numberOfSlides: 3,
                },
            },
            {
                resolve: arrowsPlugin,
                options: {
                    arrowLeft: <Icon name="chevron left" />,
                    arrowRight: <Icon name="chevron right" />,
                    arrowLeftDisabled:<Icon name="chevron left" />,
                    arrowRightDisabled: <Icon name="chevron right" />,
                    addArrowClickHandler: true,
                },
            },
            {
                resolve: autoplayPlugin,
                options: {
                  interval: 2000,
                }
            },
        ]
      }>
        {
            props.items.map(item =>
               <CarouselCard 
                    key={item.imageUrl}
                    productName={item.productName}
                    imageUrl={item.imageUrl}
                    dollarAmount={item.dollarAmount}
                    pennyAmount={item.pennyAmount}
                    description={item.description}
                    quantity={item.quantity}
                /> 
            )
        }
    </Carousel>
)
export default CarouselSlider;

