import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import "../Styles/CarouselStyles.css";

const CarouselCard = props => (
    <div className="CarouselCard">
        <Image centered src={props.imageUrl} size='mini' className="CarouselCardImage" />
        <Header textAlign="center" className="CarouselCardProductTitle" content={props.productName} />
        <Header textAlign="center" className="CarouselCardHeader">
            <Header.Content>
                <span className="CarouselCardAlternativeTitle">{props.quantity}</span>
                {
                    props.quantity !== undefined ? 
                        <sup className="CarouselCardForLabel"> FOR </sup> : null
                }
                <sup className="CarouselCardDollarSign">$</sup>
                <span className="CarouselCardMainTitle">{props.dollarAmount}</span>
                <sup className="CarouselCardSubTitle">{props.pennyAmount}</sup>
                </Header.Content>
            <Header.Subheader className="CarouselCardDescription">{props.description}</Header.Subheader>
        </Header>
    </div>
)
export default CarouselCard;

