import React from "react";
import { Grid } from "semantic-ui-react";
import StoreData from "../Data/StoreData.json";
import StoreLocations from "../Stateless Components/StoreLocations";
import StoreDepartments from "../Stateless Components/StoreDepartments";
import PageSubHeader from "../Stateless Components/PageSubHeader";
import "../Styles/StoreInfoStyles.css";

const StoreInfo = () => (
  <Grid centered>
    <Grid.Row>
        <Grid.Column>
            <PageSubHeader icon="sitemap" mainTitle="Store Departments" />
            <StoreDepartments Departments={StoreData.departments}/>
        </Grid.Column>
    </Grid.Row>
    <Grid.Row>
        <Grid.Column>
            <PageSubHeader icon="building" mainTitle="Locations" />
            <StoreLocations Locations={StoreData.locations}/>
        </Grid.Column>
    </Grid.Row>
  </Grid>
)
export default StoreInfo;